import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '@wam/icons';

import { FunctionalButtonComponent } from './functional-button.component';

@NgModule({
  declarations: [FunctionalButtonComponent],
  imports: [CommonModule, IconModule],
  exports: [FunctionalButtonComponent],
})
export class FunctionalButtonModule {}
